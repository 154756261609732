<template>
	<v-row>

		<!-- DIALOGS -->
		<v-dialog v-model="dialogOrcamentos" scrollable>
			<v-card class="primary">
				<v-card-title class="pl-4 pr-3 white--text">
					Orcamentos pendentes
					<v-spacer />
				</v-card-title>
				<v-card-text class="pa-0">
					<v-skeleton-loader v-if="carregando2" type="table-tbody" />
					<v-data-table v-else :headers="[
						{ text: 'Cód. Filial', value: 'idempresa', class: 'text-no-wrap' },
						{ text: 'Filial', value: 'empresa', class: 'text-no-wrap', sortable: false },
						{ text: 'Data', value: 'data', class: 'text-no-wrap' },
						{ text: 'Pedido', value: 'idOrcamento', class: 'text-no-wrap', sortable: false },
						{ text: 'Cód Cliente', value: 'idClifor', class: 'text-no-wrap', sortable: false },
						{ text: 'Cliente', value: 'cliente', class: 'text-no-wrap' },
						{ text: 'Vendedor', value: 'vendedor', class: 'text-no-wrap' },
						{ text: 'Valor', value: 'valortotal', class: 'text-no-wrap' },]" :items="listaOrcamentosPendentes"
						hide-default-footer disable-pagination mobile-breakpoint="0" fixed-header height="60vh"
						:expanded.sync="expanded2" expand-icon="mdi-menu-down" item-key="idOrcamento"
						class="elevation-0" :items-per-page="50" @click:row="handleRowClick2" single-expand
						:item-class="rowClass2">

						<template v-slot:item.idempresa="{ item }">
							<span class="text-truncate pr-0">{{ item.idempresa }}</span>
						</template>

						<template v-slot:item.empresa="{ item }">
							<span class="pr-0">{{ item.empresa }}</span>
						</template>

						<template v-slot:item.data="{ item }">
							<span>{{ item.data }}</span>
						</template>

						<template v-slot:item.idOrcamento="{ item }">
							<span>{{ item.idOrcamento }}</span>
						</template>

						<template v-slot:item.idClifor="{ item }">
							<span>{{ item.idClifor }}</span>
						</template>

						<template v-slot:item.cliente="{ item }">
							<span>{{ item.cliente }}</span>
						</template>

						<template v-slot:item.vendedor="{ item }">
							<span>{{ item.vendedor }}</span>
						</template>

						<template v-slot:item.valortotal="{ item }">
							{{ item.valortotal | formataDinheiro }}
						</template>

						<!-- produtos do pedido-->
						<template template v-slot:expanded-item="{}">
							<td colspan="10" class="px-0 primary">
								<v-simple-table class="table">
									<thead class="header">
										<tr class="cursor-normal">
											<th nowrap>Cod.</th>
											<th nowrap>Produto</th>
											<th nowrap>QTD</th>
											<th nowrap>Valor</th>
										</tr>
									</thead>
									<tbody class="body text-capitalize">
										<!-- Skeleton Loader enquanto carrega -->
										<template v-if="carregando3">
											<tr>
												<td colspan="4">
													<v-skeleton-loader class="white" type="table-row" />
												</td>
											</tr>
										</template>
										<!-- Verifica se há itens no array -->
										<template v-else-if="itensOrcPed.length > 0">
											<tr v-for="(item, index) in itensOrcPed" :key="index" class="cursor-normal">
												<td nowrap>{{ item.codigo }}</td>
												<td nowrap>{{ item.produto }}</td>
												<td nowrap>{{ item.qtdproduto }}</td>
												<td nowrap>{{ item.valor | formataDinheiro }}</td>
											</tr>
										</template>
									</tbody>
									<tfoot class="foot text-capitalize" v-if="itensOrcPed.length > 0">
										<tr class="cursor-normal">
											<td nowrap></td>
											<td nowrap class="bold-text"></td>
											<td nowrap class="bold-text">{{ itensOrcPed.map(v =>
												Number(v.qtdproduto)).reduce((a, v) => a + v, 0) }}</td>
											<td nowrap class="bold-text">{{ itensOrcPed.map(v =>
												Number(v.valor)).reduce((a, v) => a + v, 0) |
												formataDinheiro }}</td>
										</tr>
									</tfoot>

								</v-simple-table>
							</td>
						</template>

					</v-data-table>

				</v-card-text>
				<v-divider />
				<v-card-actions class="pa-3">
					<v-spacer />
					<v-btn class="px-5" color="primary" elevation="0"
						@click="dialogOrcamentos = false, expanded2 = []">Fechar
					</v-btn>
					<v-spacer />
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogPedidos" scrollable>
			<v-card class="primary">
				<v-card-title class="pl-4 pr-3 white--text">
					Pedidos pendentes
					<v-spacer />
				</v-card-title>
				<v-card-text class="pa-0">
					<v-skeleton-loader v-if="carregando2" type="table-tbody" />
					<v-data-table v-else :headers="[
						{ text: 'Cód. Filial', value: 'idempresa', class: 'text-no-wrap' },
						{ text: 'Filial', value: 'empresa', class: 'text-no-wrap', sortable: false },
						{ text: 'Data', value: 'data', class: 'text-no-wrap' },
						{ text: 'Pedido', value: 'idOrcamento', class: 'text-no-wrap', sortable: false },
						{ text: 'Cód Cliente', value: 'idClifor', class: 'text-no-wrap', sortable: false },
						{ text: 'Cliente', value: 'cliente', class: 'text-no-wrap' },
						{ text: 'Vendedor', value: 'vendedor', class: 'text-no-wrap' },
						{ text: 'Valor', value: 'valortotal', class: 'text-no-wrap' },]" :items="listaPedidosPendentes"
						hide-default-footer disable-pagination mobile-breakpoint="0" fixed-header height="60vh"
						:expanded.sync="expanded" expand-icon="mdi-menu-down" item-key="idOrcamento" class="elevation-0"
						:items-per-page="50" @click:row="handleRowClick" single-expand :item-class="rowClass">

						<template v-slot:item.idempresa="{ item }">
							<span class="text-truncate pr-0">{{ item.idempresa }}</span>
						</template>

						<template v-slot:item.empresa="{ item }">
							<span class="pr-0">{{ item.empresa }}</span>
						</template>

						<template v-slot:item.data="{ item }">
							<span>{{ item.data }}</span>
						</template>

						<template v-slot:item.idOrcamento="{ item }">
							<span>{{ item.idOrcamento }}</span>
						</template>

						<template v-slot:item.idClifor="{ item }">
							<span>{{ item.idClifor }}</span>
						</template>

						<template v-slot:item.cliente="{ item }">
							<span>{{ item.cliente }}</span>
						</template>

						<template v-slot:item.vendedor="{ item }">
							<span>{{ item.vendedor }}</span>
						</template>

						<template v-slot:item.valortotal="{ item }">
							{{ item.valortotal | formataDinheiro }}
						</template>

						<!-- produtos do pedido-->
						<template template v-slot:expanded-item="{}">
							<td colspan="10" class="px-0 primary">
								<v-simple-table class="table">
									<thead class="header">
										<tr class="cursor-normal">
											<th nowrap>Cod.</th>
											<th nowrap>Produto</th>
											<th nowrap>QTD</th>
											<th nowrap>Valor</th>
										</tr>
									</thead>
									<tbody class="body text-capitalize">
										<!-- Skeleton Loader enquanto carrega -->
										<template v-if="carregando3">
											<tr>
												<td colspan="4">
													<v-skeleton-loader class="white" type="table-row" />
												</td>
											</tr>
										</template>
										<!-- Verifica se há itens no array -->
										<template v-else-if="itensOrcPed.length > 0">
											<tr v-for="(item, index) in itensOrcPed" :key="index" class="cursor-normal">
												<td nowrap>{{ item.codigo }}</td>
												<td nowrap>{{ item.produto }}</td>
												<td nowrap>{{ item.qtdproduto }}</td>
												<td nowrap>{{ item.valor | formataDinheiro }}</td>
											</tr>
										</template>
									</tbody>
									<tfoot class="foot text-capitalize" v-if="itensOrcPed.length > 0">
										<tr class="cursor-normal">
											<td nowrap></td>
											<td nowrap class="bold-text"></td>
											<td nowrap class="bold-text">{{ itensOrcPed.map(v =>
												Number(v.qtdproduto)).reduce((a, v) => a + v, 0) }}</td>
											<td nowrap class="bold-text">{{ itensOrcPed.map(v =>
												Number(v.valor)).reduce((a, v) => a + v, 0) |
												formataDinheiro }}</td>
										</tr>
									</tfoot>

								</v-simple-table>
							</td>
						</template>

					</v-data-table>

				</v-card-text>
				<v-divider />
				<v-card-actions class="pa-3">
					<v-spacer />
					<v-btn class="px-5" color="primary" elevation="0"
						@click="dialogPedidos = false, expanded = []">Fechar
					</v-btn>
					<v-spacer />
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogDetalheMeta" scrollable>
			<v-card class="primary">
				<v-card-title class="pl-4 pr-3 white--text">
					Detalhe Meta Diária Acumulada
					<v-spacer />
				</v-card-title>
				<v-card-text class="pa-0">
					<v-skeleton-loader v-if="carregando2" type="table-tbody" />
					<v-data-table v-else :headers="[
						{ text: 'Data', value: 'data', class: 'text-no-wrap', sortable: false },
						{ text: 'Meta Diária Fixa', value: 'metadiariafixa', class: 'text-no-wrap', sortable: false },
						{ text: 'Meta Remanescente', value: 'metaremanescente', class: 'text-no-wrap', sortable: false },
						{ text: 'Venda do Dia', value: 'vendadiaria', class: 'text-no-wrap', sortable: false },
						{ text: 'Meta Acumulada', value: 'metadiaria', class: 'text-no-wrap', sortable: false },
						{ text: 'Saldo', value: 'realizado', class: 'text-no-wrap', sortable: false },
						{ text: 'Atingimento', value: 'atingimento', class: 'text-no-wrap', sortable: false },
						{ text: 'Dias Úteis', value: 'diasuteis', class: 'text-no-wrap', sortable: false },
					]" :items="detalheMetaDiariaAcumulada" hide-default-footer disable-pagination mobile-breakpoint="0" fixed-header
						height="60vh">

						<template v-slot:item.data="{ item }">
							<span class="text-truncate pr-0">{{ item.data }}</span>
						</template>

						<template v-slot:item.metadiariafixa="{ item }">
							<span class="pr-0">{{ item.metadiariafixa | formataDinheiro }}</span>
						</template>

						<template v-slot:item.metaremanescente="{ item }">
							<span>{{ item.metaremanescente | formataDinheiro }}</span>
						</template>

						<template v-slot:item.vendadiaria="{ item }">
							<span>{{ item.vendadiaria | formataDinheiro }}</span>
						</template>

						<template v-slot:item.metadiaria="{ item }">
							<span>{{ item.metadiaria | formataDinheiro }}</span>
						</template>

						<template v-slot:item.realizado="{ item }">
							<span>{{ item.realizado | formataDinheiro }}</span>
						</template>

						<template v-slot:item.atingimento="{ item }">
							<span>{{ item.atingimento }}%</span>
						</template>

						<template v-slot:item.diasuteis="{ item }">
							<span class="text-center">{{ item.diasuteis }}</span>
						</template>

					</v-data-table>

				</v-card-text>
				<v-divider />
				<v-card-actions class="pa-3">
					<v-spacer />
					<v-btn class="px-5" color="primary" elevation="0" @click="dialogDetalheMeta = false">Fechar
					</v-btn>
					<v-spacer />
				</v-card-actions>
			</v-card>
		</v-dialog>

		<template v-if="permissaoDashboard">
			<DialogDashboards :dialog="dialog" />
			<v-skeleton-loader v-if="carregando" class="position-center" width="100vw" height="50vh" elevation="12"
				type="table-heading, list-item-two-line, image, table-tfoot" />
			<template v-else>
				<!-- FILTROS -->
				<v-col cols="12" md="12">
					<v-card>
						<v-card-text>
							<v-form>
								<v-row v-if="filtroCompleto.indexOf(usuario.idgrupo) != -1"
									:class="dashboard.dtultimaatualizacao ? 'mb-n13' : 'mb-n10'">
									<v-col class="d-none d-md-block pl-2 pr-1 pl-lg-3">
										<InputDatePicker :disabled="carregando" v-model="busca.dtinicio"
											label="Data inicial" :outlined="true" :dense="true" />
									</v-col>
									<v-col class="d-none d-md-block px-1">
										<InputDatePicker :disabled="carregando" v-model="busca.dtfim" label="Data final"
											:outlined="true" :dense="true" />
									</v-col>
									<v-col class="d-none d-md-block px-1">
										<v-autocomplete :items="regionais" clearable item-text="regional"
											item-value="idregional" label="Regional" outlined dense
											:disabled="carregando" v-model="busca.idregional"
											@change="listarEmpresas()"></v-autocomplete>
									</v-col>
									<v-bottom-sheet class="d-inline-flex d-md-none" v-model="sheet">
										<v-sheet class="text-center" height="100%">
											<v-row class="pa-3">
												<v-col cols="12" class="px-2 mb-n7">
													<v-autocomplete :items="regionais" clearable item-text="regional"
														item-value="idregional" label="Regional" outlined dense
														:disabled="carregando" v-model="busca.idregional"
														@change="listarEmpresas()"></v-autocomplete>
												</v-col>
												<v-col cols="6" class="px-2">
													<InputDatePicker :disabled="carregando" v-model="busca.dtinicio"
														label="Data inicial" :outlined="true" :dense="true" />
												</v-col>
												<v-col cols="6" class="px-2">
													<InputDatePicker :disabled="carregando" v-model="busca.dtfim"
														label="Data final" :outlined="true" :dense="true" />
												</v-col>
												<v-col cols="6">
													<v-btn text class="mt-n3" color="primary"
														@click="sheet = !sheet">Cancelar</v-btn>
												</v-col>
												<v-col cols="6">
													<v-btn text class="mt-n3" color="primary"
														@click="get(), carrega(), sheet = !sheet">Filtrar</v-btn>
												</v-col>
											</v-row>
										</v-sheet>
									</v-bottom-sheet>
									<v-col cols="12" md="4" class="pr-1 pl-3 pl-md-1 pr-lg-3 d-flex">
										<v-autocomplete :items="filiais.lista" clearable item-text="filial"
											item-value="idfilial" label="Filial" outlined dense :disabled="carregando"
											v-model="busca.idfiliais" multiple class="d-inline-flex d-md-none"
											append-outer-icon="mdi-menu" @click:append-outer="sheet = !sheet">
											<template v-slot:selection="{ item, index }">
												<template v-if="index === 0">{{ item.filial }}</template>
												<span v-if="index === 1" class="grey--text text-caption ml-1">(+{{
													busca.idfiliais.length - 1 }})</span>
											</template>
										</v-autocomplete>
										<v-autocomplete :items="filiais.lista" clearable item-text="filial"
											item-value="idfilial" label="Filial" outlined dense :disabled="carregando"
											v-model="busca.idfiliais" multiple class="d-none d-md-block">
											<template v-slot:selection="{ item, index }">
												<template v-if="index === 0">{{ item.filial }}</template>
												<span v-if="index === 1" class="grey--text text-caption ml-1">(+{{
													busca.idfiliais.length - 1 }})</span>
											</template>
										</v-autocomplete>
										<v-btn :disabled="carregando" class="ml-2 mt-1" color="primary" elevation="0"
											fab x-small @click="get(), carrega()">
											<v-icon>mdi-magnify</v-icon>
										</v-btn>
									</v-col>
								</v-row>
								<v-row v-else-if="filtroComFilial.indexOf(usuario.idgrupo) != -1"
									:class="dashboard.dtultimaatualizacao ? 'mb-n13' : 'mb-n10'">
									<v-col class="d-none d-md-block pl-2 pr-1 pl-lg-3">
										<InputDatePicker :disabled="carregando" v-model="busca.dtinicio"
											label="Data inicial" :outlined="true" :dense="true" />
									</v-col>
									<v-col class="d-none d-md-block px-1">
										<InputDatePicker :disabled="carregando" v-model="busca.dtfim" label="Data final"
											:outlined="true" :dense="true" />
									</v-col>
									<v-bottom-sheet class="d-inline-flex d-md-none" v-model="sheet">
										<v-sheet class="text-center" height="100%">
											<v-row class="pa-3">
												<v-col cols="6" class="px-2">
													<InputDatePicker :disabled="carregando" v-model="busca.dtinicio"
														label="Data inicial" :outlined="true" :dense="true" />
												</v-col>
												<v-col cols="6" class="px-2">
													<InputDatePicker :disabled="carregando" v-model="busca.dtfim"
														label="Data final" :outlined="true" :dense="true" />
												</v-col>
												<v-col cols="6">
													<v-btn text class="mt-n3" color="primary"
														@click="sheet = !sheet">Cancelar</v-btn>
												</v-col>
												<v-col cols="6">
													<v-btn text class="mt-n3" color="primary"
														@click="get(), carrega(), sheet = !sheet">Filtrar</v-btn>
												</v-col>
											</v-row>
										</v-sheet>
									</v-bottom-sheet>
									<v-col class="pr-1 pl-3 pl-md-1 pr-lg-3 d-flex">
										<v-autocomplete :items="filiais.lista" clearable item-text="filial"
											item-value="idfilial" label="Filial" outlined dense :disabled="carregando"
											v-model="busca.idfiliais" multiple class="d-inline-flex d-md-none"
											append-outer-icon="mdi-menu" @click:append-outer="sheet = !sheet">
											<template v-slot:selection="{ item, index }">
												<template v-if="index === 0">{{ item.filial }}</template>
												<span v-if="index === 1" class="grey--text text-caption ml-1">(+{{
													busca.idfiliais.length - 1 }})</span>
											</template>
										</v-autocomplete>
										<v-autocomplete :items="filiais.lista" clearable item-text="filial"
											item-value="idfilial" label="Filial" outlined dense :disabled="carregando"
											v-model="busca.idfiliais" multiple class="d-none d-md-block">
											<template v-slot:selection="{ item, index }">
												<template v-if="index === 0">{{ item.filial }}</template>
												<span v-if="index === 1" class="grey--text text-caption ml-1">(+{{
													busca.idfiliais.length - 1 }})</span>
											</template>
										</v-autocomplete>
										<v-btn :disabled="carregando" class="ml-2 mt-1" color="primary" elevation="0"
											fab x-small @click="get(), carrega()">
											<v-icon>mdi-magnify</v-icon>
										</v-btn>
									</v-col>
								</v-row>
								<v-row v-else :class="dashboard.dtultimaatualizacao ? 'mb-n7' : 'mb-n4'">
									<v-spacer />
									<v-col cols="6" class="pl-2 pr-1">
										<InputDatePicker :disabled="carregando" v-model="busca.dtinicio"
											label="Data inicial" :outlined="true" :dense="true" />
									</v-col>
									<v-spacer />
									<v-col cols="6" md="6" class="px-1 d-flex">
										<InputDatePicker :disabled="carregando" v-model="busca.dtfim" label="Data final"
											:outlined="true" :dense="true" />
										<v-btn :disabled="carregando" class="ml-2 mt-1" color="primary" elevation="0"
											fab x-small @click="get()">
											<v-icon>mdi-magnify</v-icon>
										</v-btn>
									</v-col>
									<v-spacer />
								</v-row>
							</v-form>
						</v-card-text>
						<v-card-actions class="px-4 my-0 py-0">
							<small v-if="autoRefresh && !carregando" class="text--secondary text-caption">Próxima
								atualização em:
								{{ tempoRefresh }} segundos</small>
							<v-spacer />
							<span class="d-flex mb-n3">
								<v-switch :loading="carregando" v-model="autoRefresh" label="Atualização automática"
									color="primary" class="my-0 py-0"></v-switch>
							</span>
						</v-card-actions>
						<span v-if="dashboard.dtultimaatualizacao" class="text-caption text--disabled pl-3">* Atualizado
							em:
							{{
								dashboard.dtultimaatualizacao }}</span>
					</v-card>
				</v-col>
				<!-- CARDS -->
				<v-col cols="12" md="12">
					<!-- INDICES DE VENDA -->
					<v-card :style="{ background: 'linear-gradient(to right, #1E3A5F, #004D66)' }" dark>
						<v-card-title class="d-flex justify-center white--text text-h5 font-weight-bold">
							Índices baseados na Venda
						</v-card-title>
						<v-divider></v-divider>
						<v-card-text>
							<v-row class="pa-2">
								<v-col class="pa-1">
									<CardDashboard :loading="carregando" :title="`Venda do dia ${dia}`"
										:color="'#3D7AA8'">
										<template v-if="dashboard.vendadia">{{ dashboard.vendadia | formataDinheiro
											}}</template>
										<template v-else>{{ 0 | formataDinheiro }}</template>
									</CardDashboard>
								</v-col>
								<v-col class="pa-1">
									<CardDashboard :loading="carregando" :title="`Meta do dia ${dia}`"
										:color="'#3D7AA8'">
										<template v-if="dashboard.metadia">{{ dashboard.metadia | formataDinheiro
											}}</template>
										<template v-else>Indefinida</template>
									</CardDashboard>
								</v-col>
								<v-col class="pa-1">
									<CardDashboard :loading="carregando" title="Meta Diária Atingida (%)" :color="'#3D7AA8'">
										<template v-if="dashboard.vendadia && dashboard.metadia">{{
											(dashboard.vendadia /
												dashboard.metadia * 100).toFixed(2) }} %</template>
										<template v-else>0.00.00 %</template>
									</CardDashboard>
								</v-col>
								<v-col class="pa-1">
									<CardDashboard :loading="carregando" :title="`Margem do dia ${dia}`"
										:color="'#3D7AA8'">
										<template v-if="dashboard.margemvendadia">{{ dashboard.margemvendadia.toFixed(2)
											}}
											%</template>
										<template v-else>0.00 %</template>
										<MenuInfo texto="Margem de venda com base no apoio a decisão" />
									</CardDashboard>
								</v-col>
								<v-col class="pa-1">
									<CardDashboard :loading="carregando" title="Margem acumulada" :color="'#3D7AA8'">
										<template v-if="dashboard.margemvendas">{{ dashboard.margemvendas.toFixed(2) }}
											%</template>
										<template v-else>0.00 %</template>
										<MenuInfo texto="Margem de venda com base no apoio a decisão" />
									</CardDashboard>
								</v-col>
								<v-col class="pa-1">
									<CardDashboard :loading="carregando" title="Venda acumulada" :color="'#3D7AA8'">
										<template v-if="dashboard.vendabruta">{{ dashboard.vendabruta | formataDinheiro
											}}</template>
										<template v-else>{{ 0 | formataDinheiro }}</template>
									</CardDashboard>
								</v-col>
								<v-col class="pa-1">
									<CardDashboard :loading="carregando" title="Meta acumulada" :color="'#3D7AA8'">
										<template v-if="dashboard.metavendas">{{ dashboard.metavendas | formataDinheiro
											}}</template>
										<template v-else>Indefinida</template>
									</CardDashboard>
								</v-col>
								<v-col class="pa-1">
									<CardDashboard :loading="carregando" title="Meta diária acumulada"
										:color="'#3D7AA8'">
										<template v-if="metaDiariaAcumulada">{{ metaDiariaAcumulada | formataDinheiro
											}}</template>
										<template v-else>Indefinida</template>
										<v-tooltip right color="blue-grey" content-class='custom-tooltip' top offset-y>
											<template v-slot:activator="{ on }">
												<v-btn class="mt-n3 ml-1 mr-n8" text v-bind="attrs" fab x-small
													@click="dialogDetalheMeta = true, listaDetalheMetaDiariaAcumulada()"
													v-on="on">
													<v-icon color="white" size="16">mdi-magnify-plus</v-icon>
												</v-btn>
											</template>
											<v-icon color="white" size="20" class="mr-2">mdi-magnify-plus</v-icon>
											<span class="white--text">Clique para o detalhamento da Meta</span>
										</v-tooltip>
									</CardDashboard>

								</v-col>
								<v-col class="pa-1">
									<CardDashboard :loading="carregando" title="Meta Acumulada Atingida (%)" :color="'#3D7AA8'">
										<template v-if="dashboard.vendabruta && dashboard.metavendas">{{
											(dashboard.vendabruta /
												dashboard.metavendas * 100).toFixed(2) }} %</template>
										<template v-else>0.00.00 %</template>
									</CardDashboard>
								</v-col>
								<v-col class="pa-1">
									<CardDashboard :loading="carregando" title="Meta do mês" :color="'#3D7AA8'">
										<template v-if="dashboard.metames">{{ dashboard.metames | formataDinheiro
											}}</template>
										<template v-else>Indefinida</template>
									</CardDashboard>
								</v-col>
								<v-col class="pa-1">
									<CardDashboard :loading="carregando" title="Ticket médio pedidos / Qtd tickets"
										:color="'#3D7AA8'">
										<template v-if="dashboard.ticketmedio">{{ dashboard.ticketmedio |
											formataDinheiro }} /
											{{
												(dashboard.vendabruta / dashboard.ticketmedio).toFixed(0) }}</template>
										<template v-else>{{ 0 | formataDinheiro }} / 0</template>
									</CardDashboard>
								</v-col>
								<v-col class="pa-1">
									<CardDashboard :loading="carregando" title="Ticket médio / clientes atendidos"
										:color="'#3D7AA8'">
										<template v-if="dashboard.vendabruta && dashboard.clientesatendidos">{{
											(dashboard.vendabruta / dashboard.clientesatendidos) | formataDinheiro }} /
											{{
												dashboard.clientesatendidos }}</template>
										<template v-else>{{ 0 | formataDinheiro }} / 0</template>
									</CardDashboard>
								</v-col>
								<v-col class="pa-1">
									<CardDashboard :loading="carregando" :color="'amber accent-4'"
										title="Orçamentos Pendentes R$ / QTD Orçamentos Pendentes ">
										<template
											v-if="orcamentosPendentes[0].quantidade && orcamentosPendentes[0].valor">{{
												orcamentosPendentes[0].valor | formataDinheiro }} / {{
												orcamentosPendentes[0].quantidade
											}}</template>
										<template v-else>{{ 0 | formataDinheiro }} / 0</template>
										<v-tooltip right color="blue-grey" content-class='custom-tooltip' top offset-y>
											<template v-slot:activator="{ on }">
												<v-btn class="mt-n3 ml-1 mr-n8" text v-bind="attrs" fab x-small
													@click="dialogOrcamentos = true, listarOrcamentosPendentes()"
													v-on="on">
													<v-icon color="white" size="16">mdi-magnify-plus</v-icon>
												</v-btn>
											</template>
											<v-icon color="white" size="20" class="mr-2">mdi-magnify-plus</v-icon>
											<span class="white--text">Clique para visualizar os orçamentos</span>
										</v-tooltip>
									</CardDashboard>
								</v-col>
								<v-col class="pa-1">
									<CardDashboard :loading="carregando" :color="'red darken-3'"
										title="Pedidos de Venda Pendentes R$ / QTD Pedidos de Venda Pendentes ">
										<template v-if="pedidosPendentes[0].quantidade && pedidosPendentes[0].valor">{{
											pedidosPendentes[0].valor | formataDinheiro }} / {{
												pedidosPendentes[0].quantidade
											}}</template>
										<template v-else>{{ 0 | formataDinheiro }} / 0</template>
										<v-tooltip right color="blue-grey" content-class='custom-tooltip' top offset-y>
											<template v-slot:activator="{ on }">
												<v-btn class="mt-n3 ml-1 mr-n8" text v-bind="attrs" fab x-small
													@click="dialogPedidos = true, listarPedidosPendentes()" v-on="on">
													<v-icon color="white" size="16">mdi-magnify-plus</v-icon>
												</v-btn>
											</template>
											<v-icon color="white" size="20" class="mr-2">mdi-magnify-plus</v-icon>
											<span class="white--text">Clique para visualizar os pedidos</span>
										</v-tooltip>
									</CardDashboard>

								</v-col>
								<v-col class="pa-1">
									<CardDashboard :loading="carregando" title="Valor vendido à vista"
										:color="'#3D7AA8'">
										<template v-if="dashboard.valvendavista">
											{{ dashboard.valvendavista | formataDinheiro }}
											({{ dashboard.pervendavista }} %)
										</template>
										<template v-else>{{ 0 | formataDinheiro }}</template>
									</CardDashboard>
								</v-col>
								<v-col class="pa-1">
									<CardDashboard :loading="carregando" title="Valor vendido à prazo"
										:color="'#3D7AA8'">
										<template v-if="dashboard.valvendaprazo">
											{{ dashboard.valvendaprazo | formataDinheiro }}
											({{ dashboard.pervendaprazo }} %)
										</template>
										<template v-else>{{ 0 | formataDinheiro }}</template>
									</CardDashboard>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>

					<!-- INDICES DE FATURAMENTO -->
					<v-card class="mt-2" :style="{ background: 'linear-gradient(to right, #1E3A5F, #004D66)' }" dark>
						<v-card-title class="d-flex justify-center white--text text-h5 font-weight-bold">
							Índices baseados no Faturamento
						</v-card-title>
						<v-divider></v-divider>
						<v-card-text>
							<v-row class="pa-2">
								<v-col class="pa-1">
									<CardDashboard :loading="carregando2" title="Realizado Diário"
										:color="corRealizadoDiario">
										<template v-if="rankingFat.totalrealizadodiarioperc">
											{{ rankingFat.totalvendadiaria | formataDinheiro }} / 
											{{ (rankingFat.totalrealizadodiarioperc).toFixed(2) }} %
										</template>
										<template v-else>
											{{ 0 | formataDinheiro }} / 
											{{ 0 }} %
										</template>
									</CardDashboard>
								</v-col>
								<v-col class="pa-1">
									<CardDashboard :loading="carregando2" title="Realizado Acumulado"
										:color="corRealizadoAcumulado">
										<template v-if="rankingFat.totalrealizadoacumuladoperc">
											{{ rankingFat.vendaacumulada | formataDinheiro }} / 
											{{ (rankingFat.totalrealizadoacumuladoperc).toFixed(2) }} %
										</template>
										<template v-else>
											{{ 0 | formataDinheiro }} / 
											{{ 0 }} %
										</template>
									</CardDashboard>
								</v-col>
								<v-col class="pa-1">
									<CardDashboard :loading="carregando2" title="Margem Diária" :color="corMargemDiaria">
										<template v-if="rankingFat.totalmargemdiaria">
											{{ rankingFat.totalmargemdiaria.toFixed(2)}}%
										</template>
										<template v-else>0.00 %</template>
									</CardDashboard>
								</v-col>
								<v-col class="pa-1">
									<CardDashboard :loading="carregando2" title="Margem Acumulada"
										:color="corMargemAcumulado">
										<template v-if="rankingFat.totalmargemacumulada">
											{{ rankingFat.totalmargemacumulada.toFixed(2) }}%
										</template>
										<template v-else>0.00 %</template>
									</CardDashboard>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
				<!-- GRAFICOS-->
				<template v-if="dashboard.vendafilial">
					<v-col cols="12"
						v-if="filtroComFilial.indexOf(usuario.idgrupo) != -1 && dashboard.vendafilial.length > 1"
						class="d-none d-md-block">
						<v-card>
							<v-progress-linear indeterminate absolute :active="carregando" />
							<v-card-title>
								<span class="text-truncate">Meta atingida por filial</span>
								<v-spacer />
								<v-img class="mx-auto" max-width="220" src="../../assets/icons/logo_dark_min.png" />
								<v-spacer />
								<v-btn :disabled="carregando" class="px-2 mb-1 ml-auto mt-2 mr-2" color="primary"
									elevation="0" small @click="abrirDialog(
										dashboard.vendafilial,
										{
											descrfilial: { nome: 'Filial', tipo: 'texto' },
											valvenda: { nome: 'Venda realizada', tipo: 'dinheiro' },
											valmeta: { nome: 'Meta', tipo: 'dinheiro' },
											peratingimento: { nome: 'Atingido', tipo: 'porcentagem' },
											margem: { nome: 'Margem', tipo: 'porcentagem' },
										},
										'Venda realizada x Meta por filial'
									)">
									<v-icon size="18">mdi-magnify-plus-outline</v-icon>Ver mais
								</v-btn>
							</v-card-title>
							<v-card-text v-if="carregando">
								<v-skeleton-loader class="mx-auto" width="100%" height="300"
									type="image, image"></v-skeleton-loader>
							</v-card-text>
							<v-card-text v-else>
								<GraficoBarDashboard v-if="dashboard.vendafilial" :dataSeries="[
									{
										name: 'Atingido (%)',
										data: dashboard.vendafilial.map((v) => { return v.peratingimento.toFixed(2) })
									},
								]" :categories="dashboard.vendafilial.map((v) => { return v.descrfilial.replaceAll('(Loja 28)', '').replaceAll('Loja', '').replaceAll('( 31)', '') })"
									:horizontal="false" :money="false" :porcentagem="true" height="350" :rotate="-45"
									:fontsize="'9px'" :distributed="true"
									:dataLabels="{ position: 'bottom', orientation: 'vertical', offsetY: 20 }"
									:color="corMod1(dashboard.vendafilial, 'peratingimento')" />
								<span v-else>Nenhum registro encontrado</span>
							</v-card-text>
						</v-card>
					</v-col>
					<v-col cols="12"
						v-if="filtroComFilial.indexOf(usuario.idgrupo) != -1 && dashboard.vendafilial.length > 1"
						class="d-block d-md-none">
						<v-card>
							<v-progress-linear indeterminate absolute :active="carregando" />
							<v-card-title>
								<span class="text-truncate">Meta atingida por filial</span>
								<v-spacer />
								<v-btn :disabled="carregando" class="px-2 mb-1 ml-auto mt-2 mr-2" color="primary"
									elevation="0" small @click="abrirDialog(
										dashboard.vendafilial,
										{
											descrfilial: { nome: 'Filial', tipo: 'texto' },
											valvenda: { nome: 'Venda realizada', tipo: 'dinheiro' },
											valmeta: { nome: 'Meta', tipo: 'dinheiro' },
											peratingimento: { nome: 'Atingido', tipo: 'porcentagem' },
											margem: { nome: 'Margem', tipo: 'porcentagem' },
										},
										'Venda realizada x Meta por filial'
									)">
									<v-icon size="18">mdi-magnify-plus-outline</v-icon>
								</v-btn>
								<v-img class="mx-auto" max-width="220" src="../../assets/icons/logo_dark_min.png" />
							</v-card-title>
							<v-card-text v-if="carregando">
								<v-skeleton-loader class="mx-auto" width="100%" height="300"
									type="image, image"></v-skeleton-loader>
							</v-card-text>
							<v-card-text v-else>
								<template v-if="dashboard.vendafilial">
									<v-simple-table dense>
										<tbody>
											<tr v-for="(u, i) in dashboard.vendafilial" :key="i">
												<td style="height: 22px" width="30%" class="text-truncate text-caption">
													{{ u.descrfilial }}</td>
												<td style="height: 22px">
													<v-progress-linear rounded :value="u.peratingimento"
														:color="shadeColor('#002D56', 100 - u.peratingimento / 3)"
														class="white--text" height="17">
														<template v-slot:default="{ value }">
															<span class="text-caption">{{ value.toFixed(2) }}%</span>
														</template>
													</v-progress-linear>
												</td>
											</tr>
										</tbody>
									</v-simple-table>
								</template>
								<span v-else>Nenhum registro encontrado</span>
							</v-card-text>
						</v-card>
					</v-col>
				</template>
				<v-col cols="12" md="'12'">
					<v-card>
						<v-progress-linear indeterminate absolute :active="carregando" />
						<v-card-title class="col-12">
							<span class="text-truncate">Meta x Venda realizada por vendedor</span>
							<v-spacer />
							<v-img class="mx-auto" max-width="220" src="../../assets/icons/logo_dark_min.png" />
							<v-spacer />
							<v-btn :disabled="carregando" class="px-2 mb-1 ml-auto mt-2 mr-2" color="primary"
								elevation="0" small @click="abrirDialog(
									dashboard.vendametavendedor,
									dashboard.headervendedor,
									'Meta x Venda realizada por vendedor'
								)">
								<v-icon size="18">mdi-magnify-plus-outline</v-icon>
								<span class="d-none d-md-block">Ver mais</span>
							</v-btn>
						</v-card-title>
						<v-card-text v-if="carregando">
							<v-skeleton-loader class="mx-auto" width="100%" height="300"
								type="image, image"></v-skeleton-loader>
						</v-card-text>
						<v-card-text v-else>
							<GraficoLineColumnDashboard v-if="dashboard.vendametavendedor" :dataSeries="[
								{
									name: 'Meta',
									type: 'column',
									data: dashboard.vendametavendedor.filter(v => { return v.vendedor != 'VENDEDOR' }).filter(v => { return v.vendedor != 'Caixa Rapido' }).filter(v => { return v.venda > 0 }).slice(0, 10).map((v) => { return v.meta.toFixed(2) })
								},
								{
									name: 'Realizado',
									type: 'column',
									data: dashboard.vendametavendedor.filter(v => { return v.vendedor != 'VENDEDOR' }).filter(v => { return v.vendedor != 'Caixa Rapido' }).filter(v => { return v.venda > 0 }).slice(0, 10).map((v) => { return v.venda.toFixed(2) })
								},
								{
									name: 'Atingido',
									type: 'line',
									data: dashboard.vendametavendedor.filter(v => { return v.vendedor != 'VENDEDOR' }).filter(v => { return v.vendedor != 'Caixa Rapido' }).filter(v => { return v.venda > 0 }).slice(0, 10).map((v) => { return v.atingido.toFixed(2) })
								},
							]" :labels="dashboard.vendametavendedor.filter(v => { return v.vendedor != 'VENDEDOR' }).filter(v => { return v.vendedor != 'Caixa Rapido' }).filter(v => { return v.venda > 0 }).slice(0, 10).map((v) => { return v.vendedor.split(' ')[0] })"
								:titlesY="[
									{ show: false, min: 0, max: maiorValor() },
									{ show: false, min: 0, max: maiorValor() },
									{ show: false, min: 0, max: maiorValor() / 1000 },
								]" :tooltipsY="[
									{ enabled: true, formatter: function (val, data) { return parseFloat(val).toLocaleString('pt-br', { style: 'currency', currency: 'BRL', }) } },
									{ enabled: true, formatter: function (val, data) { return parseFloat(val).toLocaleString('pt-br', { style: 'currency', currency: 'BRL', }) } },
									{ enabled: true, formatter: function (val, data) { return `${val} %` } },
								]" height="300" :porcentagem="false" :money="false" :stroke="{ curve: 'smooth', width: [0, 0, 6] }" />
							<span v-else>Nenhum registro encontrado</span>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" class="d-none d-md-block" :md="usuario.idgrupo != 7 ? '6' : '9'">
					<v-card>
						<v-progress-linear indeterminate absolute :active="carregando" />
						<v-card-title class="col-12">
							<span class="text-truncate">Venda por categoria (%)</span>
							<v-spacer />
							<v-img class="mx-auto" max-width="220" src="../../assets/icons/logo_dark_min.png" />
							<v-spacer />
							<v-btn :disabled="carregando" class="px-2 mb-1 ml-auto mt-2 mr-2" color="primary"
								elevation="0" small @click="abrirDialog(
									dashboard.vendasecao,
									{
										descrsecao: { nome: 'Seção', tipo: 'texto' },
										valvenda: { nome: 'Valor', tipo: 'dinheiro' },
										margem: { nome: 'Margem', tipo: 'porcentagem', class: 'text-truncate' },
										percentual: { nome: 'Proporcional', tipo: 'porcentagem', class: 'text-truncate' },
									},
									'Venda realizada por categoria'
								)">
								<v-icon size="18">mdi-magnify-plus-outline</v-icon>Ver mais
							</v-btn>
						</v-card-title>
						<v-card-text v-if="carregando">
							<v-skeleton-loader class="mx-auto" width="100%" height="300"
								type="image, image"></v-skeleton-loader>
						</v-card-text>
						<v-card-text v-else>
							<GraficoBarDashboard v-if="dashboard.vendasecao" :dataSeries="[
								{
									name: 'Categoria',
									data: dashboard.vendasecao.map((v) => { return v.percentual })
								},
							]" :categories="dashboard.vendasecao.map((v) => { return v.descrsecao })" :horizontal="false" :money="false"
								:porcentagem="true" :composto="false" height="300" rotate="-50" fontsize="8px"
								:distributed="true"
								:dataLabels="{ position: 'bottom', orientation: 'vertical', offsetY: 20 }"
								:color="corMod2(dashboard.vendasecao, 'percentual')" />
							<span v-else>Nenhum registro encontrado</span>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" class="d-block d-md-none">
					<v-card>
						<v-progress-linear indeterminate absolute :active="carregando" />
						<v-card-title>
							<span class="text-truncate">Venda por categoria (%)</span>
							<v-spacer />
							<v-img class="mx-auto" max-width="220" src="../../assets/icons/logo_dark_min.png" />
							<v-btn :disabled="carregando" class="px-2 mb-1 ml-auto mt-2 mr-2" color="primary"
								elevation="0" small @click="abrirDialog(
									dashboard.vendasecao,
									{
										descrsecao: { nome: 'Seção', tipo: 'texto' },
										valvenda: { nome: 'Valor', tipo: 'dinheiro' },
										margem: { nome: 'Margem', tipo: 'porcentagem', class: 'text-truncate' },
										percentual: { nome: 'Proporcional', tipo: 'porcentagem', class: 'text-truncate' },
									},
									'Venda realizada por categoria'
								)">
								<v-icon size="18">mdi-magnify-plus-outline</v-icon>
							</v-btn>
						</v-card-title>
						<v-card-text v-if="carregando">
							<v-skeleton-loader class="mx-auto" width="100%" height="300"
								type="image, image"></v-skeleton-loader>
						</v-card-text>
						<v-card-text v-else>
							<template v-if="dashboard.vendasecao">
								<v-simple-table dense>
									<tbody>
										<tr v-for="(u, i) in dashboard.vendasecao" :key="i">
											<td style="height: 22px" width="30%" class="text-truncate text-caption">
												{{ u.descrsecao | formataTextoCurto }}</td>
											<td style="height: 22px">
												<v-progress-linear rounded :value="u.percentual"
													:color="shadeColor('#002D56', 100 - u.percentual / 3)"
													class="white--text" height="17">
													<template v-slot:default="{ value }">
														<span class="text-caption">{{ value.toFixed(2) }}%</span>
													</template>
												</v-progress-linear>
											</td>
										</tr>
									</tbody>
								</v-simple-table>
							</template>
							<span v-else>Nenhum registro encontrado</span>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" md="6" class="d-none d-md-block">
					<v-card>
						<v-progress-linear indeterminate absolute :active="carregando" />
						<v-card-title class="mb-n1">
							Margem de lucro por categoria (%)
							<v-spacer />
							<v-img class="mx-auto" max-width="220" src="../../assets/icons/logo_dark_min.png" />
						</v-card-title>
						<v-card-text v-if="carregando">
							<v-skeleton-loader class="mx-auto" width="100%" height="300"
								type="image, image"></v-skeleton-loader>
						</v-card-text>
						<v-card-text v-else>
							<GraficoBarDashboard v-if="dashboard.vendasecao" :dataSeries="[
								{
									name: 'Categoria',
									data: dashboard.vendasecao.map((v) => { return v.margem.toFixed(2) })
								},
							]" :categories="dashboard.vendasecao.map((v) => { return v.descrsecao })" :rotate="-50" :fontsize="'8px'"
								:horizontal="false" :money="false" :porcentagem="true" height="300" :distributed="true"
								:dataLabels="{ position: 'bottom', orientation: 'vertical', offsetY: 20 }"
								:color="corMod2(dashboard.vendasecao, 'margem')" />
							<span v-else>Nenhum registro encontrado</span>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" class="d-block d-md-none">
					<v-card>
						<v-progress-linear indeterminate absolute :active="carregando" />
						<v-card-title>
							<span class="text-truncate">Margem de lucro por categoria (%)</span>
							<v-spacer />
							<v-img class="mx-auto" max-width="220" src="../../assets/icons/logo_dark_min.png" />
						</v-card-title>
						<v-card-text v-if="carregando">
							<v-skeleton-loader class="mx-auto" width="100%" height="300"
								type="image, image"></v-skeleton-loader>
						</v-card-text>
						<v-card-text v-else>
							<template v-if="dashboard.vendasecao">
								<v-simple-table dense>
									<tbody>
										<tr v-for="(u, i) in dashboard.vendasecao" :key="i">
											<td style="height: 22px" width="30%" class="text-truncate text-caption">
												{{ u.descrsecao | formataTextoCurto }}</td>
											<td style="height: 22px">
												<v-progress-linear rounded :value="u.margem"
													:color="shadeColor('#002D56', 100 - u.margem / 3)"
													class="white--text" height="17">
													<template v-slot:default="{ value }">
														<span class="text-caption">{{ value.toFixed(2) }}%</span>
													</template>
												</v-progress-linear>
											</td>
										</tr>
									</tbody>
								</v-simple-table>
							</template>
							<span v-else>Nenhum registro encontrado</span>
						</v-card-text>
					</v-card>
				</v-col>
			</template>
		</template>
		<template v-else>
			<v-col>
				<v-card class="pa-5" color="primary" dark>
					<v-card-title class="mb-1">Bem-vindo</v-card-title>
					<v-card-text class="text-body-1">Para navegar pelo sistema selecione uma opção no menu
						acima</v-card-text>
				</v-card>
			</v-col>
		</template>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import InputDatePicker from "../Widgets/InputDatePicker";
import CardDashboard from "../Widgets/CardDashboard";
import MenuInfo from "../Widgets/MenuInfo";
import GraficoBarDashboard from "../Widgets/GraficoBarDashboard";
import DialogDashboards from "../Widgets/DialogDashboards";
import GraficoLineColumnDashboard from "../Widgets/GraficoLineColumnDashboard";

export default {
	name: "DashboardVendas",
	components: {
		InputDatePicker,
		CardDashboard,
		MenuInfo,
		GraficoBarDashboard,
		DialogDashboards,
		GraficoLineColumnDashboard,
	},
	data: () => ({
		tempoRefresh: 60,
		autoRefresh: false,
		usuariosAutorizados: [1, 4, 5, 6, 8, 20],
		filtroCompleto: [1, 4, 5, 8, 20],
		filtroComFilial: [1, 4, 5, 8, 20],
		sheet: false,
		dialog: { status: false, dados: [], header: {} },
		carregando: false,
		carregando2: false,
		carregando3: false,
		dialogOrcamentos: false,
		dialogPedidos: false,
		dialogDetalheMeta: false,
		expanded: [],
		expanded2: [],
		filiais: [],
		regionais: [],
		dashboard: {},
		ranking: [],
		pedidosPendentes: [],
		orcamentosPendentes: [],
		listaOrcamentosPendentes: [],
		listaPedidosPendentes: [],
		itensOrcPed: [],
		rankingFat: [],
		metaDiariaAcumulada: 0,
		detalheMetaDiariaAcumulada: [],
		perAcumuladoDiario: 0,
		perDiario: 0,
		dia: `${new Date().toLocaleDateString("pt-BR")}`,
		busca: {
			dtinicio: `${new Date().getFullYear()}-${(new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getMonth() +
				1 <
				10
				? "0"
				: "") +
				(new Date().getMonth() + 1)
				}-01`,
			// dtfim: `${new Date(
			// 	new Date().getFullYear(),
			// 	new Date().getMonth() + 1,
			// 	0
			// ).getFullYear()}-${(new Date(
			// 	new Date().getFullYear(),
			// 	new Date().getMonth() + 1,
			// 	0
			// ).getMonth() +
			// 	1 <
			// 	10
			// 	? "0"
			// 	: "") +
			// (new Date().getMonth() + 1)
			// 	}-${new Date(
			// 		new Date().getFullYear(),
			// 		new Date().getMonth() + 1,
			// 		0
			// 	).getDate()}`
			dtfim: new Date().toISOString().split("T")[0],
			idfiliais: [],
		},
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
		permissaoDashboard() {
			if (this.usuariosAutorizados.indexOf(this.usuario.idgrupo) != -1) {
				return true;
			} else {
				return false;
			}
		},
		corRealizadoDiario() {
			return this.realizadoCor(parseFloat(this.rankingFat.totalrealizadodiarioperc.toFixed(2)));
		},
		corRealizadoAcumulado() {
			return this.realizadoCor(parseFloat(this.rankingFat.totalrealizadoacumuladoperc.toFixed(2)));
		},
		corMargemDiaria() {
			return this.margemCor(parseFloat(this.rankingFat.totalmargemdiaria.toFixed(2)));
		},
		corMargemAcumulado() {
			return this.margemCor(parseFloat(this.rankingFat.totalmargemacumulada.toFixed(2)));
		},
	},
	methods: {
		listarEmpresas() {
			this.busca.idfiliais = [];
			return axios
				.post(`${this.backendUrl}filial/listar`, {
					limit: 9999,
					offset: 0,
					idregional: this.busca.idregional || null,
					busca: null,
				})
				.then((res) => {
					this.filiais = res.data;
					this.filiais.lista = this.filiais.lista.filter((v) => {
						return (
							v.idfilial !== 6 &&
							v.idfilial !== 12 &&
							v.idfilial !== 22 &&
							v.idfilial !== 25 &&
							v.idfilial !== 29 &&
							v.idfilial !== 32 &&
							v.idfilial !== 33 &&
							v.idfilial !== 34 &&
							v.idfilial !== 37
						);
					});
				});
		},
		listarRegionais() {
			return axios
				.post(`${this.backendUrl}filial/regional/listar`)
				.then((res) => {
					this.regionais = res.data;
				});
		},
		get() {
			let idfiliais = null;
			if (this.busca.idfiliais.length > 0) {
				idfiliais = this.busca.idfiliais.join(",");
			}
			if (this.usuario.idgrupo == 6) {
				idfiliais = `${this.usuario.idfilial}`;
			}
			this.dashboard = {};
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}dashboard/principal/antiga`, {
					dtini: this.busca.dtinicio,
					dtfim: this.busca.dtfim,
					idregional: this.busca.idregional || null,
					idfilial: idfiliais || null,
				})
				.then((res) => {
					this.dashboard = res.data;
					this.dashboard.vendametavendedor.forEach((v) => {
						if (v.meta != 0) {
							v.atingido = (v.venda / v.meta) * 100;
						} else {
							v.atingido = 0;
						}
					});
					this.dashboard.vendafilial =
						this.dashboard.vendafilial.sort(
							(a, b) => b.peratingimento - a.peratingimento
						);
					this.dashboard.vendasecao = this.dashboard.vendasecao.map(
						(v) => {
							return {
								...v,
								percentual:
									(v.valvenda /
										this.dashboard.vendasecao
											.map((ve) => {
												return ve.valvenda;
											})
											.reduce((a, va) => {
												return a + va;
											})) *
									100,
							};
						}
					);
					if (this.usuario.idgrupo == 7) {
						this.dashboard.headervendedor = {
							filial: { nome: "Filial", tipo: "texto" },
							idvendedor: { nome: "ID", tipo: "texto" },
							vendedor: { nome: "Vendedor", tipo: "texto" },
							meta: { nome: "Meta", tipo: "dinheiro" },
							venda: { nome: "Realizado", tipo: "dinheiro" },
							atingido: { nome: "Atingido", tipo: "porcentagem" },
						};
					} else {
						this.dashboard.headervendedor = {
							filial: { nome: "Filial", tipo: "texto" },
							idvendedor: { nome: "ID", tipo: "texto" },
							vendedor: { nome: "Vendedor", tipo: "texto" },
							meta: { nome: "Meta", tipo: "dinheiro" },
							venda: { nome: "Realizado", tipo: "dinheiro" },
							atingido: { nome: "Atingido", tipo: "porcentagem" },
							margem: { nome: "Atingido", tipo: "porcentagem" },
						};
					}
					this.carregando = false;
					if (this.autoRefresh) {
						this.tempoRefresh = 60;
						setTimeout(() => {
							this.get();
						}, 60000);
					}
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		async carrega() {
			await this.listarTotalPedidosPendentes();
			await this.listarTotalOrcamentosPendentes();
			await this.listaMetaDiariaAcumulada();
			await this.listaIndicesFat();
		},
		listarTotalPedidosPendentes() {
			this.pedidosPendentes = [];
			let data;
			if (this.usuario.idgrupo !== 6) {
				data = null
			} else {
				data = String(this.usuario.idfilial)
			}
			return axios
				.post(`${this.backendUrl}dashboard/principal/antiga/pedpendentes`, {
					dtini: this.busca.dtinicio,
					dtfim: this.busca.dtfim,
					idfilial: this.busca.idfiliais.join(",") || data,
				})
				.then((res) => {
					this.pedidosPendentes = res.data;
					this.carregando = false;
				})
				.catch(() => {
					this.carregando = false;
					this.pedidosPendentes = [];
				});
		},
		listarPedidosPendentes() {
			this.carregando2 = true;
			this.listaPedidosPendentes = [];
			let data;
			if (this.usuario.idgrupo !== 6) {
				data = null
			} else {
				data = String(this.usuario.idfilial)
			}
			return axios
				.post(`${this.backendUrl}dashboard/principal/vendas/pedpendentes/lista`, {
					dtini: this.busca.dtinicio,
					dtfim: this.busca.dtfim,
					idfilial: this.busca.idfiliais.join(",") || data,
				})
				.then((res) => {
					this.listaPedidosPendentes = res.data;
					this.carregando2 = false;
				})
				.catch(() => {
					this.carregando2 = false;
					this.listaPedidosPendentes = [];
				});
		},
		listarTotalOrcamentosPendentes() {
			this.orcamentosPendentes = [];
			let data;
			if (this.usuario.idgrupo !== 6) {
				data = null
			} else {
				data = String(this.usuario.idfilial)
			}
			return axios
				.post(`${this.backendUrl}dashboard/principal/antiga/orcpendentes`, {
					dtini: this.busca.dtinicio,
					dtfim: this.busca.dtfim,
					idfilial: this.busca.idfiliais.join(",") || data,
				})
				.then((res) => {
					this.orcamentosPendentes = res.data;
					this.carregando = false;
				})
				.catch(() => {
					this.carregando = false;
					this.orcamentosPendentes = [];
				});
		},
		listarOrcamentosPendentes() {
			this.carregando2 = true;
			this.listaOrcamentosPendentes = [];
			let data;
			if (this.usuario.idgrupo !== 6) {
				data = null
			} else {
				data = String(this.usuario.idfilial)
			}
			return axios
				.post(`${this.backendUrl}dashboard/principal/vendas/orcpendentes/lista`, {
					dtini: this.busca.dtinicio,
					dtfim: this.busca.dtfim,
					idfilial: this.busca.idfiliais.join(",") || data,
				})
				.then((res) => {
					this.listaOrcamentosPendentes = res.data;
					this.carregando2 = false;
				})
				.catch(() => {
					this.carregando2 = false;
					this.listaOrcamentosPendentes = [];
				});
		},
		listaItensOrcPed(pedido) {
			this.carregando3 = true;
			this.itensOrcPed = [];
			return axios
				.post(`${this.backendUrl}dashboard/principal/vendas/orcpedpendentes/produtos/lista`, {
					idorcamento: pedido,
				})
				.then((res) => {
					this.itensOrcPed = res.data;
					this.carregando3 = false;
					console.log(this.itensOrcPed)
				})
				.catch(() => {
					this.carregando3 = false;
					this.itensOrcPed = [];
				});
		},
		listaMetaDiariaAcumulada() {
			this.metaDiariaAcumulada = 0;
			let data;
			if (this.usuario.idgrupo !== 6) {
				data = null
			} else {
				data = String(this.usuario.idfilial)
			}
			return axios
				.post(`${this.backendUrl}dashboard/principal/vendas/meta/diaria/acumulada`, {
					dtini: this.busca.dtinicio,
					dtfim: this.busca.dtfim,
					idfilial: this.busca.idfiliais.join(",") || data,
				})
				.then((res) => {
					this.metaDiariaAcumulada = res.data;
				})
				.catch(() => {
					this.carregando = false;
					this.metaDiariaAcumulada = 0;
				});
		},
		listaDetalheMetaDiariaAcumulada() {
			this.carregando2 = true;
			this.detalheMetaDiariaAcumulada = [];
			let data;
			if (this.usuario.idgrupo !== 6) {
				data = null
			} else {
				data = String(this.usuario.idfilial)
			}
			return axios
				.post(`${this.backendUrl}dashboard/principal/vendas/meta/diaria/acumulada/detalhada`, {
					dtini: this.busca.dtinicio,
					dtfim: this.busca.dtfim,
					idfilial: this.busca.idfiliais.join(",") || data,
				})
				.then((res) => {
					this.detalheMetaDiariaAcumulada = res.data;
					this.carregando2 = false;
				})
				.catch(() => {
					this.carregando2 = false;
					this.detalheMetaDiariaAcumulada = [];
				});
		},
		listaIndicesFat(){
			this.carregando2 = true;
			this.rankingFat = [];
			let data;
			if (this.usuario.idgrupo !== 6) {
				data = null
			} else {
				data = String(this.usuario.idfilial)
			}
			return axios
				.post(`${this.backendUrl}dashboard/ranking/faturamento/dre/listar`, {
					idfilial: this.busca.idfiliais.join(",") || data,
					dtini: this.busca.dtinicio,
					dtfim: this.busca.dtfim,
				})
				.then((res) => {
					this.rankingFat = res.data;
					this.carregando2 = false;
				})
				.catch(() => {
					this.carregando2 = false;
			});
		},
		rowClass(item) {
			return this.expanded.includes(item) ? 'primary white--text' : '';
		},
		handleRowClick(item) {// toggleExpand(item)
			this.carregando3 = true;
			if (this.expanded.includes(item)) {
				// Se o item já estiver expandido, remove-o da lista de expandidos
				this.listaItensOrcPed(item.idOrcamento);
				this.expanded = this.expanded.filter(i => i !== item);
			} else {
				// Caso contrário, define a lista de expandidos para conter apenas o item atual
				this.listaItensOrcPed(item.idOrcamento);
				this.expanded = [item];
			}
		},
		rowClass2(item) {
			return this.expanded2.includes(item) ? 'primary white--text' : '';
		},
		handleRowClick2(item) {// toggleExpand(item)
			this.carregando3 = true;
			if (this.expanded2.includes(item)) {
				// Se o item já estiver expandido, remove-o da lista de expandidos
				this.listaItensOrcPed(item.idOrcamento);
				this.expanded2 = this.expanded2.filter(i => i !== item);
			} else {
				// Caso contrário, define a lista de expandidos para conter apenas o item atual
				this.listaItensOrcPed(item.idOrcamento);
				this.expanded2 = [item];
			}
		},
		timerRefresh() {
			if (this.tempoRefresh < 0) {
				this.tempoRefresh = 60;
			}
			setTimeout(() => {
				this.tempoRefresh--;
				this.timerRefresh();
			}, 1000);
		},
		abrirDialog(dados, header, title) {
			this.dialog.status = true;
			this.dialog.dados = dados;
			this.dialog.header = header;
			this.dialog.title = title;
		},
		maiorValor() {
			let array = this.dashboard.vendametavendedor
				.filter((v) => {
					return v.vendedor != "VENDEDOR";
				})
				.filter((v) => {
					return v.vendedor != "Caixa Rapido";
				})
				.filter((v) => {
					return v.venda > 0;
				})
				.slice(0, 10)
				.map((v) => {
					return v.meta.toFixed(2);
				});

			this.dashboard.vendametavendedor
				.filter((v) => {
					return v.vendedor != "VENDEDOR";
				})
				.filter((v) => {
					return v.vendedor != "Caixa Rapido";
				})
				.filter((v) => {
					return v.venda > 0;
				})
				.slice(0, 10)
				.map((v) => {
					array.push(v.venda.toFixed(2));
				});

			return (parseInt(Math.max.apply(null, array) / 100) + 1) * 100;
		},
		corMod1(array, campo) {
			let cores = [];
			cores = array.map((v) => {
				let cor;
				cor = this.corSemafaro(v[campo]);
				return this.shadeColor(cor, 100 - v[campo] / 3);
			});
			return cores;
		},
		corMod2(array, campo) {
			let cores = [];
			cores = array.map((v) => {
				let cor = "#002D56";
				return this.shadeColor(cor, 100 - v[campo] / 3);
			});
			return cores;
		},
		corSemafaro(valor) {
			let cor;
			if (valor >= 90) cor = "#002D56";
			if (valor < 90) cor = "#F2B90F";
			if (valor < 70) cor = "#951015";
			return cor;
		},
		shadeColor(color, percent) {
			var R = parseInt(color.substring(1, 3), 16);
			var G = parseInt(color.substring(3, 5), 16);
			var B = parseInt(color.substring(5, 7), 16);
			R = parseInt((R * (100 + percent)) / 100);
			G = parseInt((G * (100 + percent)) / 100);
			B = parseInt((B * (100 + percent)) / 100);
			R = R < 255 ? R : 255;
			G = G < 255 ? G : 255;
			B = B < 255 ? B : 255;
			var RR =
				R.toString(16).length == 1
					? "0" + R.toString(16)
					: R.toString(16);
			var GG =
				G.toString(16).length == 1
					? "0" + G.toString(16)
					: G.toString(16);
			var BB =
				B.toString(16).length == 1
					? "0" + B.toString(16)
					: B.toString(16);
			return "#" + RR + GG + BB;
		},
		margemCor(valor) {
			if (valor <= 20.50) {
				return 'red darken-3';
			} else if (valor >= 20.51 && valor <= 24.50) {
				return 'orange darken-2';
			}
			else if (valor >= 24.51) {
				return 'green darken-3';
			}
		},
		realizadoCor(valor) {
			if (valor <= 60.00) {
				return 'red darken-3';
			} else if (valor >= 60.01 && valor <= 90.50) {
				return 'orange darken-2';
			}
			else if (valor >= 90.51) {
				return 'green darken-3';
			}
		},
		async init() {
			await this.listarEmpresas();
			await this.listarRegionais();
			await this.get();
			await this.listaIndicesFat();
			this.carrega();
			this.timerRefresh();
		},
	},
	watch: {
		"busca.dtfim": function () {
			if (this.busca.dtfim < this.busca.dtinicio) {
				this.busca.dtinicio = this.busca.dtfim;
			}
		},
		"busca.dtinicio": function () {
			if (this.busca.dtfim < this.busca.dtinicio) {
				this.busca.dtfim = this.busca.dtinicio;
			}
		},
		autoRefresh: function () {
			if (this.autoRefresh) {
				this.tempoRefresh = 60;
				this.get();
				this.listarTotalPedidosPendentes();
			}
		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
.table {
	margin: 0px 0px 10px 0px;
}

.header th {
	background-color: #CFD8DC !important;
	height: 25px !important;
}

.body td {
	height: 20px !important;
	font-size: larger;
}

.bold-text {
	font-weight: bold;
}

.foot td {
	background-color: #CFD8DC !important;
	height: 20px !important;
}
</style>